import * as React from 'react'
import { GlobalDrawer } from '../../../molecules/GlobalDrawer'
import styles from './SkillsDrawer.module.scss'
import { SkillsDrawerProps } from './SkillsDrawer.types'
import { SKILLS_DRAWER_ID } from './utils'
import { SkillsDrawerProvider } from './context'
import { SkillsDrawerContent } from './DrawerContent'

export const SkillsDrawer: React.VFC<SkillsDrawerProps> = (props) => {
  const {
    source,
    collections,
    eventHandlers,
    frameworkSkills,
    frameworkName,
    orgName,
    showAddedSkills,
    showLoadingSpinner,
    showNoResults,
    skillGeneratorUrl,
    orgSkillsUrl,
    skills,
    showAiSkillGenerator = true,
    title: baseTitle,
    ...restProps
  } = props

  const title = baseTitle || 'Add skills to your framework'

  return (
    <SkillsDrawerProvider
      collections={collections}
      eventHandlers={eventHandlers}
      skills={skills}
      frameworkSkills={frameworkSkills}
    >
      <GlobalDrawer
        id={SKILLS_DRAWER_ID}
        title={title}
        className={styles.drawer}
        {...restProps}
      >
        <SkillsDrawerContent
          source={source}
          collections={collections}
          eventHandlers={eventHandlers}
          frameworkName={frameworkName}
          orgName={orgName}
          showAddedSkills={showAddedSkills}
          showAiSkillGenerator={showAiSkillGenerator}
          showLoadingSpinner={showLoadingSpinner}
          showNoResults={showNoResults}
          skillGeneratorUrl={skillGeneratorUrl}
          orgSkillsUrl={orgSkillsUrl}
          skills={skills}
          title={baseTitle}
        />
      </GlobalDrawer>
    </SkillsDrawerProvider>
  )
}
