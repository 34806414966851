import * as React from 'react'
import { TCollection } from '../../../../types/entities'
import { CollectionCard } from '../CollectionCard'
import { PropsWithoutChildren } from '../../../../types/helpers'

export type CollectionListProps = {
  source: 'library' | 'org' | 'all'
  /**
   * Array of collections to display in the list
   */
  collections?: readonly TCollection[]
  /**
   * Hook called when the user adds a collection from the drawer
   */
  onAddCollection?(collection: TCollection): void
  /**
   * Hook called when the user clicks a collection
   */
  onClick?(collection: TCollection): void
  /**
   * Whether or not the collection cards are draggable
   */
  draggable?: boolean
} & PropsWithoutChildren<Omit<React.ComponentPropsWithoutRef<'div'>, 'onClick'>>

export const CollectionList: React.VFC<CollectionListProps> = (props) => {
  const {
    collections = [],
    source,
    onClick,
    onAddCollection,
    draggable,
    ...restProps
  } = props

  return (
    <div {...restProps}>
      {collections.length && (
        <div className="p-4 text-gray-500">Collections</div>
      )}
      {collections.map((collection: TCollection) => (
        <CollectionCard
          draggable={draggable}
          key={collection.id}
          source={source}
          collection={collection}
          onAddCollection={onAddCollection}
          onClick={onClick}
        />
      ))}
    </div>
  )
}
