import * as React from 'react'
import cn from 'classnames'
import type { PropsWithoutChildren } from '../../../types/helpers'
import styles from './Input.module.scss'
import { Search } from '@easy-eva-icons/react'
import { SearchContext } from './SearchContext'
import { composeEventHandlers } from '../../../utils/compose-event-handlers'
import { Input } from '../../atoms/Input'

export type SearchInputProps = {
  /**
   * Optional props to pass through to the label which wraps around the input - can be used for positioning if needed
   */
  labelProps?: PropsWithoutChildren<React.ComponentPropsWithoutRef<'label'>>
} & Omit<
  PropsWithoutChildren<React.ComponentPropsWithoutRef<'input'>>,
  'role' | 'autoComplete' | 'type'
>

/**
 * A search input component which supports hitting escape to clear the current query, as well as passing any content up to the `<Search.Root>` component which can subsequently perform external searches using the passed through search object, containing things like query, sort, filters etc.
 */
export const SearchInput: React.VFC<SearchInputProps> = (props) => {
  const { labelProps, className, onKeyDown, onChange, ...restProps } = props

  const { search, updateSearch, resetQuery } = React.useContext(SearchContext)

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') resetQuery()
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateSearch({ query: e.target.value })
  }

  return (
    <label
      {...labelProps}
      className={cn('relative flex', labelProps?.className)}
    >
      <Search aria-hidden className={cn(styles.searchIcon)} />
      <Input
        type="search"
        role="searchbox"
        autoComplete="off"
        value={search.query}
        onKeyDown={composeEventHandlers(onKeyDown, handleKeyDown)}
        onChange={composeEventHandlers(onChange, handleOnChange)}
        className={cn(styles.input, className)}
        {...restProps}
      />
    </label>
  )
}
