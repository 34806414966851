import { merge } from 'merge-anything'
import * as React from 'react'
import {
  TCollection,
  TSkill,
  TSkillVariant,
  TSkillWithOrg,
} from '../../../../../types/entities'
import { SkillsDrawerProps, SkillType } from '../SkillsDrawer.types'
import { SkillsDrawerContext } from './SkillsDrawerContext'

type SkillsDrawerProviderProps = {
  children: React.ReactNode
  frameworkSkills?: readonly TSkill[]
  skills?: readonly TSkillWithOrg[]
  collections?: readonly TCollection[]
  eventHandlers: SkillsDrawerProps['eventHandlers']
}

export const SkillsDrawerProvider = (props: SkillsDrawerProviderProps) => {
  const {
    children,
    skills = [],
    collections = [],
    frameworkSkills = [],
    eventHandlers,
    ...restProps
  } = props

  /**
   * Org skills are matched by its `id`
   */
  const usedFrameworkSkillsIDs = React.useMemo(() => {
    return frameworkSkills.map((s) => Number(s.id))
  }, [frameworkSkills])

  const getSkill = (skillId: string) => {
    return skills.find((s) => s.id.toString() === skillId)
  }

  /**
   * Function for checking if the skill is in use in the skills grid
   */
  const inCurrentFramework = (skill: TSkill): boolean => {
    if (skill.cloneable) return false

    return usedFrameworkSkillsIDs.includes(Number(skill.id))
  }

  const usedFrameworkSkillVariantsIDs = React.useMemo(() => {
    return frameworkSkills
      .map((s) =>
        s.skillVariants
          ?.filter((v) => v.inCurrentFramework)
          .map((v) => Number(v.id))
      )
      .flat()
  }, [frameworkSkills])

  const inCurrentFrameworkVariant = (variant: TSkillVariant): boolean => {
    return usedFrameworkSkillVariantsIDs.includes(Number(variant.id))
  }

  const inCurrentFrameworkAnyVariant = (
    skillVariants: TSkillVariant[]
  ): boolean => {
    return skillVariants.some((skillVariant) =>
      inCurrentFrameworkVariant(skillVariant)
    )
  }

  const providerProps = merge(restProps, {
    collections,
    eventHandlers,
    getSkill,
    inCurrentFramework,
    inCurrentFrameworkAnyVariant,
    inCurrentFrameworkVariant,
    skills,
  })

  return (
    <SkillsDrawerContext.Provider value={providerProps}>
      {children}
    </SkillsDrawerContext.Provider>
  )
}
