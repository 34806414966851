import * as React from 'react'
import { merge } from 'merge-anything'
import type { DistributiveOmit } from '../../../types/helpers'
import * as Sort from '../Sort'
import { TSort } from '../Sort/utils'
import { SearchContext, SearchContextProps } from './SearchContext'
import { useDebouncedCallback } from 'use-debounce'
import { SearchObject } from './Search.types'

export type RootProps = {
  /**
   * Event handler called when any search related content changes such as query, sort, filters etc.
   * @example onSearchChange={(search) => console.log(search.query)}
   */
  onSearchChange?: (search: SearchObject) => void | Promise<void>
  /**
   * Optional debounce time in ms to implement throttling on the onSearchChange function. By default this is 0, but you could set it to a value like 200 which would ensure 200ms had passed since the last change before calling the onSearchChange handler
   * @example debounceTime={200}
   */
  debounceTime?: number
  /**
   * Children to pass through to the root. If passed a function you can access the internal search object
   * @example
   * <Search.Root>
   *   {({ search }) => (
   *     <div>Query: {search.query}</div>
   *   )}
   * </Search.Root>
   */
  children:
    | React.ReactNode
    | ((ctx: {
        /**
         * Search object containing query, sort, filters etc.
         */
        search: SearchContextProps['search']
        /**
         * method for updating the search object programmatically, useful for things like changing filters when swapping between tabs. This will merge any new values with the existing search object
         * @example updateSearch({ filters: { source: "library" } })
         */
        updateSearch: SearchContextProps['updateSearch']
      }) => React.ReactNode)
} & DistributiveOmit<Sort.RootProps, 'onSortChange' | 'children'>

/**
 * A group of search related components which support passing a single search object containing query, sort, filters etc. to a change handler which can subsequently perform external searches with the information provided. Handles things like hitting `Esc` to clear search input.
 */
export const Root: React.VFC<RootProps> = (props) => {
  const { children, onSearchChange, debounceTime = 0, ...restProps } = props

  const [search, setSearch] = React.useState({
    query: '',
    sort: props.initialSort,
  })

  const debouncedOnSearchChange = useDebouncedCallback(
    (search: SearchObject) => {
      onSearchChange?.(search)
    },
    debounceTime
  )

  const updateSearch = React.useCallback(
    (newSearch: SearchObject) => {
      setSearch((search) => {
        const combinedSearch = merge({ ...search }, newSearch)
        if (debounceTime > 0) debouncedOnSearchChange(combinedSearch)
        else onSearchChange?.(combinedSearch)
        return combinedSearch
      })
    },
    [debounceTime]
  )

  const resetQuery = () => updateSearch({ query: '' })
  const updateSort = (sort: TSort) => updateSearch({ sort })

  const content =
    children instanceof Function ? children({ search, updateSearch }) : children

  return (
    <SearchContext.Provider
      value={{ search, updateSearch, resetQuery, onSearchChange }}
    >
      <Sort.Root onSortChange={updateSort} {...restProps}>
        {content}
      </Sort.Root>
    </SearchContext.Provider>
  )
}
