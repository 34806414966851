import * as React from 'react'
import { useDrawerContext } from '../../../../../contexts/drawer-context'
import * as TabbedContent from '../../../TabbedContent'
import { NewSkillForm } from '../../NewSkillForm'
import styles from '../SkillsDrawer.module.scss'

export type SkillsDrawerNewTabProps = {
  onCreateNewSkill?: (name: string) => void | Promise<void>
}

export const SkillsDrawerNewTab: React.VFC<SkillsDrawerNewTabProps> = (
  props
) => {
  const { onCreateNewSkill } = props

  const { closeDrawer } = useDrawerContext()

  return (
    <TabbedContent.Content tabId="new" className={styles.tabbedContent}>
      <NewSkillForm
        onSubmit={async (name) => {
          await onCreateNewSkill?.(name)
          closeDrawer()
        }}
        className={styles.newForm}
      />
    </TabbedContent.Content>
  )
}
