import { ArrowLeft, X } from '@phosphor-icons/react'
import * as React from 'react'
import { useDrawerContext } from '../../../../contexts/drawer-context'
import styles from './DrawerHeader.module.scss'

type HeaderProps = {
  title: string
  onClickBack?: () => void
}

export const Header = (props: HeaderProps) => {
  const { title, onClickBack } = props

  const { closeDrawer } = useDrawerContext()

  return (
    <div className={styles.header}>
      {!!onClickBack && (
        <button onClick={onClickBack} title="Go back">
          <ArrowLeft weight="bold" className={styles.backIcon} />
        </button>
      )}
      <h3 className={styles.title}>{title}</h3>
      <button onClick={closeDrawer} title="Close drawer">
        <X weight="bold" className={styles.closeIcon} />
      </button>
    </div>
  )
}
