import * as React from 'react'
import { SkillCard } from './SkillCard'
import styles from './SkillsDrawer.module.scss'
import type { TSkill } from '../../../../types/entities'

export type SkillsListProps = {
  source: 'library' | 'org' | 'all'
  skills?: readonly TSkill[]
  allowEdit: boolean
}

export const SkillsList = (props: SkillsListProps) => {
  const { source, skills = [], ...restProps } = props

  const filteredSkills = skills // change to show added skills at all times to avoid blank page

  return (
    <div className={styles.skillsList}>
      <div className={styles.sortedBy}>
        {source === 'library'
          ? 'Sorted by: Most popular'
          : 'Sorted by: Most used'}
      </div>
      {filteredSkills.length > 0 ? (
        filteredSkills.map((skill) => (
          <SkillCard
            key={`${skill.cloneable ? 'library' : 'org'}-${skill.id}`}
            source={source}
            skill={skill}
            {...restProps}
          />
        ))
      ) : (
        <div className="">
          <div className="px-4 pt-8 text-center text-gray-500">
            <p>No skills available</p>
          </div>
          <div className="flex flex-col">
            {Array.from({ length: 3 }).map((_, index) => (
              <div key={index} className="p-4 w-full flex gap-x-4">
                <div className="w-16 h-16 rounded bg-gray-25"></div>
                <div className="py-2 flex flex-col items-start h-full gap-2">
                  <div className="h-3 rounded-full bg-gray-25 w-36"></div>
                  <div className="h-3 rounded-full bg-gray-25 w-24"></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
