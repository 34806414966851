import * as React from 'react'
import * as TabbedContent from '../../TabbedContent'
import { useDrawerProps } from '../../../../hooks/use-drawer-props'
import { TCategory } from '../../../../types/entities'
import { SkillsDrawerProps } from './SkillsDrawer.types'
import { SKILLS_DRAWER_ID } from './utils'
import { Header } from './DrawerHeader'
import { Loader } from '../../../atoms/Loader'
import { SkillsDrawerDefaultTab, SkillsDrawerNewTab } from './tabs'
import { SkillsDrawerFooter } from './Footer'
import styles from './DrawerContent.module.scss'

export type SkillsDrawerContentProps = Pick<
  SkillsDrawerProps,
  | 'source'
  | 'collections'
  | 'eventHandlers'
  | 'frameworkName'
  | 'orgName'
  | 'showAddedSkills'
  | 'showAiSkillGenerator'
  | 'showLoadingSpinner'
  | 'showNoResults'
  | 'skillGeneratorUrl'
  | 'orgSkillsUrl'
  | 'skills'
  | 'title'
>

export const SkillsDrawerContent: React.VFC<SkillsDrawerContentProps> = (
  props
) => {
  const {
    source,
    collections,
    eventHandlers,
    frameworkName,
    orgName,
    showAddedSkills,
    showAiSkillGenerator,
    showLoadingSpinner,
    showNoResults,
    skillGeneratorUrl,
    orgSkillsUrl,
    skills,
    title: baseTitle,
  } = props

  const initialTabId = 'default'

  const { category } = useDrawerProps<{ category: TCategory | null }>(
    SKILLS_DRAWER_ID
  )

  let title = baseTitle || `Add skills to ${frameworkName || 'your framework'}`
  if (source === 'library') title = `Add skills to ${orgName || 'your org'}`
  if (category) title = `Add skills to "${category.name}"`

  return (
    <TabbedContent.Root initialTabId={initialTabId} className={styles.content}>
      {({ activeTab, setActiveTab }) => (
        <>
          <Header
            title={activeTab === 'new' ? 'Create new skill' : title}
            onClickBack={
              activeTab === 'new' ? () => setActiveTab(initialTabId) : undefined
            }
          />
          <SkillsDrawerFooter
            source={source}
            categoryId={category?.id.toString()}
            onClickCreateNewSkill={(categoryId) => {
              eventHandlers?.onClickCreateSkill?.(categoryId)
              setActiveTab('new')
            }}
            showAiSkillGenerator={showAiSkillGenerator}
            skillGeneratorUrl={skillGeneratorUrl}
            orgSkillsUrl={orgSkillsUrl}
          />
          {showLoadingSpinner && <Loader className={styles.loader} />}

          {!showLoadingSpinner && (
            <>
              <SkillsDrawerDefaultTab
                source={source}
                skills={skills}
                orgName={orgName}
                collections={collections}
                showNoResults={showNoResults}
                showAddedSkills={showAddedSkills}
              />

              <SkillsDrawerNewTab
                onCreateNewSkill={(name) => {
                  eventHandlers?.onCreateNewSkill?.(
                    name,
                    category?.id.toString()
                  )
                }}
              />
            </>
          )}
        </>
      )}
    </TabbedContent.Root>
  )
}
