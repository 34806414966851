import * as React from 'react'
import type { PropsWithRequiredChildren } from '../../../types/helpers'
import { SortContext } from './SortContext'
import type { TSort } from './utils'

type WithoutDefaultSort = {
  /**
   * Prop to disable default sorting. This must be used in conjunction with `initialSort`, so the context knows which sort to apply as a starting point
   */
  allowDefaultSort: false
  /**
   * Required sort object used to set the initial sort. This is required when setting the `allowDefaultSort` prop to false, so the context knows which sort to apply as a starting point
   */
  initialSort: NonNullable<TSort>
}

type WithDefaultSort = {
  allowDefaultSort?: never
  initialSort?: TSort
}

export type RootProps = {
  onSortChange?: (newSort: TSort) => void
} & PropsWithRequiredChildren<React.ComponentPropsWithoutRef<'div'>> &
  (WithDefaultSort | WithoutDefaultSort)

export const Root: React.VFC<RootProps> = (props) => {
  const {
    children,
    initialSort,
    allowDefaultSort,
    onSortChange,
    ...restProps
  } = props

  const [activeSort, setActiveSort] = React.useState<TSort>(initialSort || null)

  return (
    <SortContext.Provider
      value={{ activeSort, setActiveSort, onSortChange, allowDefaultSort }}
    >
      <div {...restProps}>{children}</div>
    </SortContext.Provider>
  )
}
