import * as React from 'react'
import {
  TCategory,
  TCollection,
  TSkillWithOrg,
} from '../../../../../types/entities'
import * as TabbedContent from '../../../TabbedContent'
import styles from '../SkillsDrawer.module.scss'
import * as Search from '../../../../molecules/Search'
import { useSkillsDrawer } from '../context'
import { useDrawerContext } from '../../../../../contexts/drawer-context'
import { SkillsList } from '../SkillsList'
import { CollectionList } from '../../CollectionList'
import { SKILLS_DRAWER_ID } from '../utils'
import { SearchObject } from '../../../../molecules/Search'

export type SkillsDrawerDefaultTabProps = {
  source: 'library' | 'org' | 'all'
  orgName?: string
  skills?: readonly TSkillWithOrg[]
  collections?: readonly TCollection[]
  /**
   * Whether or not to show the "No results" message
   */
  showNoResults?: boolean
  /**
   * Whether or not to show already added skills in the drawer. Useful for hiding them from an initial state
   */
  showAddedSkills?: boolean
}

export const SkillsDrawerDefaultTab: React.VFC<SkillsDrawerDefaultTabProps> = (
  props
) => {
  const {
    source,
    orgName,
    skills = [],
    collections = [],
    showNoResults,
    showAddedSkills,
  } = props

  const { closeDrawer, getDrawerProps } = useDrawerContext()

  const {
    eventHandlers: { onAddCollection, onSearchChange, onClickCollection },
  } = useSkillsDrawer()

  const { category } = getDrawerProps<{ category: TCategory | null }>(
    SKILLS_DRAWER_ID
  )

  const addCollection = React.useCallback(
    (collection: TCollection) => {
      onAddCollection?.(collection, category?.id.toString())
      closeDrawer()
    },
    [onAddCollection, closeDrawer]
  )

  const onSearchChangeHandler = (search: SearchObject) => {
    onSearchChange?.(search)
  }

  const placeholder = React.useMemo(() => {
    if (source === 'library') return "Search Progression's Library"
    return `Search ${orgName || 'your org'}'s skills`
  }, [source, category])

  return (
    <TabbedContent.Content tabId="default" className={styles.tabbedContent}>
      <Search.Root
        allowDefaultSort={false}
        initialSort={{ id: 'created_at', direction: 'desc' }}
        onSearchChange={onSearchChangeHandler}
        debounceTime={200}
      >
        {!!onSearchChange && (
          <Search.Input
            autoFocus
            placeholder={placeholder}
            onKeyDown={(e) => e.stopPropagation()}
            labelProps={{ className: styles.searchInput }}
          />
        )}

        <SkillsList source={source} skills={skills} allowEdit />

        {source === 'library' && (
          <CollectionList
            draggable
            source={source}
            collections={collections}
            onAddCollection={addCollection}
            onClick={onClickCollection}
          />
        )}

        {showNoResults && <div className={styles.noResults}>No results</div>}
      </Search.Root>
    </TabbedContent.Content>
  )
}
