import * as React from 'react'
import cn from 'classnames'
import { Drawer } from '../../../molecules/Drawer'
import styles from './Footer.module.scss'
import { Book, Plus, Sparkle } from '@phosphor-icons/react'

export type SkillsDrawerFooterProps = {
  showAiSkillGenerator?: boolean
  skillGeneratorUrl?: string
  orgSkillsUrl?: string
  onClickCreateNewSkill?: (categoryId?: string) => void
  onClickGenerateSkill?: (categoryId?: string) => void
  categoryId?: string
  source: string
}

export const SkillsDrawerFooter: React.VFC<SkillsDrawerFooterProps> = (
  props
) => {
  const {
    source,
    categoryId,
    showAiSkillGenerator,
    skillGeneratorUrl,
    orgSkillsUrl,
    onClickGenerateSkill,
    onClickCreateNewSkill,
  } = props

  const content = (
    <>
      {showAiSkillGenerator && (
        <a
          href={skillGeneratorUrl}
          onClick={() => onClickGenerateSkill?.(categoryId)}
          className={styles.item}
        >
          <div
            className={cn(
              styles.iconContainer,
              styles.generateNewSkillIconContainer
            )}
          >
            <Sparkle weight="bold" className={styles.icon} />
          </div>
          <div className="flex flex-col items-start">
            <h5 className={styles.title}>Generate new skill</h5>
            <p className={styles.description}>
              AI Assistant · Powered by GPT-3
            </p>
          </div>
        </a>
      )}

      <button
        type="button"
        onClick={() => onClickCreateNewSkill?.(categoryId)}
        className={styles.item}
      >
        <div className={cn(styles.iconContainer, styles.newSkillIconContainer)}>
          <Plus weight="bold" className={styles.icon} />
        </div>
        <div className="flex flex-col items-start">
          <h5 className={styles.title}>Create new skill</h5>
          {!showAiSkillGenerator && (
            <p className={styles.description}>
              Build from scratch with our AI Assistant
            </p>
          )}
        </div>
      </button>
    </>
  )

  if (source === 'library') {
    return <div className={styles.header}>{content}</div>
  }

  return (
    <Drawer.Footer animation={false} className={styles.footer}>
      {content}
      {orgSkillsUrl && (
        <a href={orgSkillsUrl} className={styles.item}>
          <div
            className={cn(
              styles.iconContainer,
              styles.addFromLibraryIconContainer
            )}
          >
            <Book weight="bold" className={styles.icon} />
          </div>
          <div className="flex flex-col items-start">
            <h5 className={styles.title}>Add from library</h5>
            <p className={styles.description}>
              Add new skills to your org from Progression&apos;s library
            </p>
          </div>
        </a>
      )}
    </Drawer.Footer>
  )
}
